<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            {{ createForm ? 'Create Location' : 'Update Location' }}
            <v-spacer />
            <v-btn
                v-if="!createForm"
                depressed
                color="primary"
                rounded
                icon
                class=""
                @click="update"
            >
                <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn
                v-if="!createForm"
                depressed
                rounded
                color="primary"
                @click="openDeleteDialog"
                icon
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-card-title>
        <v-dialog
            v-model="deleteDialog"
            :retain-focus="false"
            persistent
            max-width="400px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">Delete Location</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this location?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteLocation"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-text class="py-0">
            <vue-google-autocomplete
                id="search"
                placeholder="Search"
                v-on:placechanged="getAddressData"
                class="autocomplete-input"
                :country="['us', 'co']"
            >
            </vue-google-autocomplete>
            <l-map
                style="height: 300px"
                :zoom="zoom"
                :center="center"
                @click="onMapClick"
            >
                <l-tile-layer
                    :url="url"
                    :attribution="attribution"
                ></l-tile-layer>
                <l-marker :lat-lng="markerLatLng"></l-marker>
            </l-map>
            <v-form
                ref="form"
                v-model="valid"
                :disabled="!createForm && !isEditing"
                class="my-1"
            >
                <v-row class="mt-2">
                    <v-col cols="12" lg="6" class="py-0">
                        <v-text-field
                            v-model="location.name"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            prepend-icon="mdi-map-marker-outline"
                            label="Name *"
                            required
                        />
                    </v-col>
                    <v-col cols="12" lg="6" class="py-0">
                        <v-text-field
                            v-model="location.notes"
                            :readonly="!createForm && !isEditing"
                            prepend-icon="mdi-text"
                            label="Notes "
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col cols="12" lg="6" class="py-0">
                        <v-text-field
                            v-model="location.location._latitude"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            type="number"
                            prepend-icon="mdi-latitude"
                            label="Latitude *"
                            required
                        />
                    </v-col>
                    <v-col cols="12" lg="6" class="py-0">
                        <v-text-field
                            v-model="location.location._longitude"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            prepend-icon="mdi-longitude"
                            label="Longitude *"
                            type="number"
                            required
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="createForm"
                text
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="saveExternalUser"
            >
                Save
            </v-btn>
            <v-btn
                :disabled="!valid || Object.keys(locationDiff) == 0"
                v-if="isEditing"
                text
                color="red"
                :loading="loading"
                @click="saveExternalUser"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { Icon } from 'leaflet'

export default {
    name: 'GeolocationForm',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        VueGoogleAutocomplete,
    },
    props: {
        loc: {
            type: Object,
            default() {
                return {}
            },
        },
        createForm: {
            type: Boolean,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 5,
            center: [0, 0],
            markerLatLng: [0, 0],
            isEditing: false,
            editing: null,
            editingIndex: -1,
            deleteDialog: false,
            index: null,
            company: JSON.parse(localStorage.getItem('company')),
            loading: false,
            error: false,
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
            },
            deleteError: false,
            originalLocation: {},
            customName: '',
            suggestions: null,
            location: {},
        }
    },
    computed: {
        locationDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalLocation, this.location)
            } else {
                return null
            }
        },
    },
    async mounted() {
        this.location = _.cloneDeep(this.loc)
        if (this.location.location) {
            this.zoom = 17
        } else {
            this.location = {
                location: { _latitude: 4.648, _longitude: -74.078 },
                name: '',
            }
        }
        this.originalLocation = _.cloneDeep(this.location)
        this.markerLatLng = [
            this.location.location._latitude,
            this.location.location._longitude,
        ]
        this.center = [
            this.location.location._latitude,
            this.location.location._longitude,
        ]
        delete Icon.Default.prototype._getIconUrl
        Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        })
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openDeleteDialog() {
            this.deleteDialog = true
        },
        closeDialog() {
            this.$refs.form.reset()
            this.error = false
            this.errorMsg = null
            this.isEditing = false
            this.$emit('closeDialog')
        },
        update() {
            this.isEditing = !this.isEditing
        },
        saveExternalUser: async function() {
            if (this.createForm) {
                await this.createProjectLocation()
            } else {
                await this.updateProjectLocation()
            }
        },
        async updateProjectLocation() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                await API.updateProjectLocation({
                    projectId: this.projectId,
                    locationId: this.location.id,
                    ...this.locationDiff,
                })
                this.$emit('replaceProjectLocation', this.location)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async createProjectLocation() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                Object.keys(this.location).forEach(key => {
                    if (
                        this.location[key] === undefined ||
                        this.location[key] === ''
                    ) {
                        delete this.location[key]
                    }
                })
                const newProjectLocation = await API.createProjectLocation({
                    projectId: this.projectId,
                    ...this.location,
                })
                this.$emit('addProjectLocation', newProjectLocation)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        async deleteLocation() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                await API.deleteProjectLocation({
                    projectId: this.projectId,
                    locationId: this.location.id,
                })
                this.$emit('removeProjectLocation', this.location)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getAddressData(place) {
            if (this.createForm || this.isEditing) {
                this.location.location = {
                    _latitude: place.latitude,
                    _longitude: place.longitude,
                }
                this.markerLatLng = [place.latitude, place.longitude]
            }
            this.center = [place.latitude, place.longitude]
            this.suggestions = place
        },

        onMapClick(event) {
            if (this.createForm || this.isEditing) {
                this.location.location = {
                    _latitude: event.latlng.lat,
                    _longitude: event.latlng.lng,
                }
                this.markerLatLng = [event.latlng.lat, event.latlng.lng]
                this.center = this.markerLatLng
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.vue-tel-input {
    border: none;
    border-radius: 0px;
    font-size: 16px;
}
.vue-tel-input.disabled {
    opacity: 0.5;
}

.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: 'primary';
    color: 'primary';
}

.autocomplete-input {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    outline: none;
    margin-bottom: 10px;
}
</style>
